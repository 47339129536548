import * as THREE from 'three';
import { store } from '../../../store/store';
export class Events {
    constructor(camera) {
        this._forward = false;
        this._backward = false;
        this._left = false;
        this._right = false;
        this.walkingSpeed = 1.85;
        store.currentSession.subscribe(value => {
            this.playerIsInScene = value;
        });
        this.render(camera);
    }
    get backward() {
        return this._backward;
    }
    set backward(value) {
        this._backward = value;
    }
    get forward() {
        return this._forward;
    }
    set forward(value) {
        this._forward = value;
    }
    get left() {
        return this._left;
    }
    set left(value) {
        this._left = value;
    }
    get right() {
        return this._right;
    }
    set right(value) {
        this._right = value;
    }
    handleUserDirection(camera) {
        this.frontVector = new THREE.Vector3(0, 0, Number(this.backward) - Number(this.forward));
        this.sideVector = new THREE.Vector3(Number(this.left) - Number(this.right), 0, 0);
        this.userDirection = new THREE.Vector3();
        this.userDirection
            .subVectors(this.frontVector, this.sideVector)
            .normalize()
            .multiplyScalar(this.walkingSpeed)
            .applyEuler(camera.rotation);
    }
    render(camera) {
        const _camera = camera;
        this.handleUserDirection(camera);
        window.requestAnimationFrame(() => this.render(_camera));
    }
    handleKeyUpEvents() {
        window.addEventListener('keyup', e => {
            if (e.key === 's') {
                this.backward = false;
            }
            if (e.key === 'w') {
                this.forward = false;
            }
            if (e.key === 'a') {
                this.left = false;
            }
            if (e.key === 'd') {
                this.right = false;
            }
        });
    }
    handleKeyDownEvents() {
        window.addEventListener('keydown', e => {
            if (this.playerIsInScene) {
                if (e.key === 's') {
                    this.backward = true;
                }
                if (e.key === 'w') {
                    this.forward = true;
                }
                if (e.key === 'a') {
                    this.left = true;
                }
                if (e.key === 'd') {
                    this.right = true;
                }
            }
        });
    }
}
