export var IconType;
(function (IconType) {
    IconType["bottom"] = "bottom";
    IconType["right"] = "right";
    IconType["up"] = "up";
    IconType["left"] = "left";
    IconType["escape"] = "escape";
    IconType["mouse"] = "mouse";
    IconType["phone"] = "phone";
    IconType["instagram"] = "instagram";
    IconType["logo"] = "logo";
    IconType["arrowDown"] = "arrowDown";
    IconType["close"] = "close";
    IconType["headphone"] = "headphone";
    IconType["headphoneMuted"] = "headphoneMuted";
    IconType["soundKey"] = "soundKey";
    IconType["a"] = "a";
    IconType["w"] = "w";
    IconType["d"] = "d";
    IconType["s"] = "s";
})(IconType || (IconType = {}));
