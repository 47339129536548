import { writable } from 'svelte/store';
import { mobileCheck } from '../utils/mobileCheck';
const audio = new Audio('./static/sound/ambient-sound.mp3');
audio.loop = true;
audio.volume = 0.125;
class Store {
    constructor() {
        this.progressRatio = writable(0);
        this.currentSession = writable(false);
        this.pointerLockerControls = writable();
        this.isMobileDevice = writable(mobileCheck());
        this.deviceOrientation = writable();
        this.canvasContainer = writable();
        this.layoutContainer = writable();
        this.audioController = writable(audio);
        this.isMuted = writable(false);
        this.xrIsSupported = writable();
        this.scrollSpeed = writable(0);
        this.creativeIndex = writable(null);
        this.vrSession = writable(false);
    }
}
export const store = new Store();
