import GSAP from 'gsap';
import { store } from '../store/store';
let audio;
export const interval = 250;
let soundIsMuted;
store.isMuted.subscribe(value => {
    soundIsMuted = value;
});
export const onEnter = (el) => {
    let pointerLockerctrls;
    let isMobileDevice;
    let deviceOrientation;
    GSAP.to(el, { duration: 0.5, opacity: 0 });
    store.audioController.subscribe(value => {
        audio = value;
    });
    store.pointerLockerControls.subscribe(value => {
        pointerLockerctrls = value;
    });
    store.isMobileDevice.subscribe(value => {
        isMobileDevice = value;
    });
    store.deviceOrientation.subscribe(value => {
        deviceOrientation = value;
    });
    store.currentSession.update(() => true);
    playSound(audio);
    handleSoundOnPageVisibility();
    setTimeout(() => {
        el.style.display = 'none';
    }, 500);
    if (isMobileDevice) {
        deviceOrientation.enabled = true;
        deviceOrientation.connect();
    }
    else {
        if (pointerLockerctrls) {
            pointerLockerctrls.lock();
            pointerLockerctrls.addEventListener('unlock', () => {
                audio.pause();
                GSAP.to(el, { duration: 0.5, opacity: 1 });
                el.style.display = 'block';
                store.currentSession.update(() => false);
            });
        }
    }
    store.currentSession.update(() => true);
};
export const onExit = (el) => {
    pauseSound(audio);
    let deviceOrientation;
    store.deviceOrientation.subscribe(value => {
        deviceOrientation = value;
    });
    deviceOrientation.disconnect();
    GSAP.to(el, { duration: 0.5, opacity: 1 });
    el.style.display = 'block';
    store.currentSession.update(() => false);
};
function handleSoundOnPageVisibility() {
    const hidden = 'hidden';
    const visibilityChange = 'visibilitychange';
    document.addEventListener(visibilityChange, () => {
        if (document[hidden]) {
            audio.pause();
        }
        else {
            store.currentSession.subscribe(value => {
                if (value) {
                    playSound(audio);
                }
            });
        }
    });
}
export function playSound(sound) {
    if (!soundIsMuted) {
        sound.volume = 0.2;
        sound.play();
    }
}
export function pauseSound(sound) {
    if (!soundIsMuted) {
        sound.pause();
    }
}
